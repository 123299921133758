<template>
  <div class="home h-100 container">
    <div class="row justify-content-center h-100">
      <div>  
        
        <div class="card p-2 mb-2">
          <h2 class="mt-4 mb-4">SNPWA HEALTH INSURANCE SCHEME 2024-2025</h2>
        <div class="row logos-main">
          <div class="col-6 col-sm-3"><img src="../assets/img/0001.jpg"></div>
          <div class="col-6 col-sm-3 border-right"><img src="../assets/img/NIA_LOGO.png"></div>
          <div class="col-6 col-sm-3 border-right-sm"><img src="../assets/img/0002.jpg"></div>
          <div class="col-6 col-sm-3 border-right"><img src="../assets/img/HEALTH INDIA_LOGO.jpeg"></div>          
        </div>
        </div>
        <div class="card p-2">
          <h2 class="mb-2 mt-4">POLICY COVERAGE</h2>  
          <div class="container p-0">    
            <div class="row gx-5">
              <div class="col-6 col-sm-6">
                <a href="doc/2024-2025/BSNL_-_POLICY_COVERAGE_-_2024-2025 (2).docx" target="_blank">
                <div class="card bg-grey">
                  <div class="card-body">
                    <h5 class="card-title">POLICY COVERAGE</h5>
                  </div>
                </div> 
                </a>             
              </div>
              <div class="col-6 col-sm-6">
                <a href="doc/2024-2025/Permanent Exclusions_24-25.docx" target="_blank">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">PERMANENT EXCLUSIONS</h5>
                  </div>
                </div>    
                </a>          
              </div>
              <div class="col-6 col-sm-6 mt-2">
                <a href="doc/2024-2025/DAY CARE PROCEDURES - LIST_24-25.pdf" target="_blank">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">DAY CARE PROCEDURES</h5>
                  </div>
                </div>
                </a> 
              </div>
              <!-- <div class="col-6 col-sm-6 mt-2">
                <a href="doc/2023-2024/SNPWA Premium Structure - Top-up & Children.pdf" target="_blank">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">SEPARATE COVERAGE FACILITY (TOP UP POLICY AND CHILDREN POLICY)</h5>
                  </div>
                </div> 
                </a>
              </div> -->
              <div class="col-6 col-sm-6 mt-2">
                <a href="doc/2024-2025/IRDAI - Non Payable Items List (Non Medical Items)_24-25.pdf" target="_blank">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">IRDAI APPROVED NON-PAYABLE ITEMS</h5>
                  </div>
                </div> 
                </a>
              </div>
              <div class="col-6 col-sm-6 mt-2">
                <a href="doc/2024-2025/General Information_24-25.docx" target="_blank">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">GENERAL INFORMATION</h5>
                  </div>
                </div> 
                </a>
              </div>
              <div class="col-6 col-sm-6 mt-2">
                <a href="doc/2024-2025/SNPWA_-_FAQ_-_2024-2025.docx" target="_blank">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">FAQ</h5>
                  </div>
                </div> 
                </a>
              </div>
              <div class="col-6 col-sm-12 mt-2">
                <a href="doc/2024-2025/THE NEW INDIA ASSURANCE CO. LTD.pdf" target="_blank">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title"> MODE OF PAYMENT</h5>
                  </div>
                </div> 
                </a>
              </div>
              <!-- <div class="col-6 col-sm-6 mt-2">
                <a href="doc/2023-2024/SNPWA - Parents' Premium Table.pdf" target="_blank">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">SNPWA PARENT'S PREMIUM TABLE</h5>
                  </div>
                </div> 
                </a>
              </div> -->
              <ul class="m-2 mt-4">
                <li>CLAIMANTS IN THE POLICY PERIOD FROM 27-03-2023 TO 26-03-2024 SHALL HAVE TO PAY AN ADDITIONAL ‘LOADED PREMIUM ‘IN THE PROPOTION TO THE AMOUNT SETTLED FOR THE CLAIMS LODGED BY THEM
                    NB: CLAIMS SETTLED UPTO 12-03-2024 WERE UPDATED AND THOSE CLAIMANTS LOADED PREMIUM SHALL BE AUTOMATICALLY COMPUTED ALONG WITH THE BASIC PREMIUM. AS FAR AS LOADED PREMIUM ON CLAIMS SETTLED AFTER 12-03-2024 SHALL BE INTIMATED TO THE CLAIMANTS ACCORDINGLY AND THE CLAIMANTS ARE REQUESTED TO PAY THEIR LOADED PREMIUM IN ADDITION TO THE BASIC PREMIUM WITHIN THE TIME ALLOWED WITHOUT FAIL
                </li>
                <li>FOR THE POLICY PERIOD 2024-2025 LOADING CHARGES WILL BE REPLACED BY CO-PAYMENT OF 10% SINCE THAT AMOUNT HAS TO GO DIRECT TO THE NIA, WHILE PREMIUM COLLECTIONS ARE MANDATED TO BE DONE BY THE ASSOCIATION.</li>
                <li style="font-weight: bold;">EXISTING MEMBERS WITHOUT CLAIMS IF DISCONTINUED IN THIS YEAR SHALL NOT BE ALLOWED TO RE-ENTER FOR THE NEXT 3 YEARS AND MEMBERS WITH CLAIMS NOT BE ALLOWED FOR LIFE TIME</li>
                <li>THOSE MEMBERS WHO FIND IT DIFFICULT TO EXECUTE ONLINE REGISTRATION ARE REQUESTED TO CONTACT MR. RAM K V (8893093088)</li>
                <li>ONCE YOU HAVE UPDATED THE DETAILS, YOU ARE NOT ABLE TO CORRECT IT. FOR FURTHER CORRECTION YOU MAY CONTACT SECURUS HELPLINE (ARUN S -9947720008)</li>
                <li>PLEASE ENSURE THAT YOUR NAME MATCHES WITH YOUR AADHAAR CARD OR YOUR OFFICIAL RECORDS</li>
                <li>SNPWA IS NOT RESPONSIBLE FOR ANY MEMBER WHO MAKES A DIRECT PAYMENT TO THE INSURANCE COMPANY'S BANK ACCOUNT. WE HAVE STRICT INSTRUCTIONS FROM THE INSURANCE COMPANY TO COLLECT ALL PREMIUM PAYMENTS THROUGH THE SNPWA BANK ACCOUNT</li>
                <li>TO ENSURE UNINTERRUPTED RENEWAL OF YOUR MEMBERSHIP, PLEASE INCLUDE YOUR HR NUMBER AND PHONE NUMBER IN THE REMARKS FIELD WHEN MAKING YOUR PREMIUM PAYMENT.</li>
              </ul>
              <div class="col-12 col-sm-12">
                <a href="doc/2024-2025/SNPWA_-_PREMIUM_COMPUTATION_-2024-2025__Final_.docx" target="_blank">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">PREMIUM CHART 2024-2025</h5>
                  </div>
                </div> 
                </a>
              </div>
            </div>
          </div>    
        </div>    
        <div class="transparent mb-2 mt-2">
          <button type="submit" class="btn btn-primary btn-block btn-lg" @click.prevent="routeToLogin"><span>Enroll</span></button>
        </div>
      </div>
    </div>    
    <!-- <login v-if="isNotExpired"/>
    <expired v-else/> -->
  </div>
</template>

<script>
// @ is an alias to /src'
// import Login from "../components/Login";
// import Expired from "../components/Expired";
export default {
  name: 'Home',
  components: {
    // Login,
    // Expired
  },
  data() {
    return {
      isNotExpired: true,
      pdfLink: require('../assets/doc/SNPWA Logo Final.pdf')
    }
  },
  methods: {
    routeToLogin() {
      this.$router.push({path: '/login'})
    }
  }
}
</script>
<style lang="scss" scoped>
  .logos-main img {
    height: 150px;
  }
  p {
    text-align: justify !important;
  }
  .h-100 {
    align-items: center !important;
  }
  .gx-5 {
    .col-6 {
      margin: 10px 0;
    }    
    .card {      
      height: 100%;
      background: #edeff1;
      .card-body {
        display: flex;
        justify-content: center;
        align-items: center;
        h5 {
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
  }
  .border-right {
    border-right: 1px solid #e0e0e0;
  }
  @media only screen and (min-width: 600px)  {
    .border-right-sm {
      border-right: 1px solid #e0e0e0;
    }   
  }
  ul {
  }
  ul li {
    text-align: left;
    font-size: 12px;
    font-weight: 500;
  }
  
</style>